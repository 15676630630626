import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'react-emotion'
import { Tabs } from 'antd'

import Paragraph from '../../../../components/paragraph'
import Layout from '../../../../components/layout'

import FWPlayers from '../../../../components/tables/fort-worth/players'
import FWTeams from '../../../../components/tables/fort-worth/teams'

const { TabPane } = Tabs

const LeaderboardContainer = styled.div`
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 50px;
    width: 1024px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px;
    width: 100%;
  }

  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th {
    padding: 0px 20px;
  }
`

const isLeaderboardEnabled = true

const formatForTable = edges => {
  return edges.map(edge => {
    return edge.node
  })
}

const IndexPage = ({ data, location }) => (
  <Layout location={location}>
    <LeaderboardContainer>
      <h1>Leaderboards</h1>
      <h4>CWL Fort Worth</h4>
      {!isLeaderboardEnabled && (
        <Paragraph>This event has yet to begin! Please check back later.</Paragraph>
      )}
      {isLeaderboardEnabled && (
        <Tabs>
          <TabPane tab="Players" key="players">
            <FWPlayers tableData={formatForTable(data.allLeaderboardPlayersFwCsv.edges)} />
          </TabPane>
          <TabPane tab="Teams" key="teams">
            <FWTeams tableData={formatForTable(data.allLeaderboardTeamsFwCsv.edges)} />
          </TabPane>
        </Tabs>
      )}
    </LeaderboardContainer>
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}

export default IndexPage

export const query = graphql`
  query {
    allLeaderboardPlayersFwCsv {
      edges {
        node {
          Player
          Team
          RespawnRating
          RespawnKD
          RespawnSPM
          RespawnDPM
          RespawnEPM
          RespawnPTD
          RespawnDeathPM
          RespawnMaps
          SnDRating
          SnDKD
          SnDSPR
          SnDDPR
          SnDEPR
          SnDDeathPR
          SnDFBPct
          SnDFBFD
          SnDMaps
          HPRating
          HPKD
          HPSPM
          HPDPM
          HPEPM
          HPPTD
          HPDeathPM
          HillTime
          HPMaps
          ControlRating
          ControlKD
          ControlSPM
          ControlDPM
          ControlEPM
          CLU
          ControlDeathPM
          ControlMaps
          OverallRating
          OverallKD
          aKD
          Xfactor
          OverallMaps
        }
      }
    }
    allLeaderboardTeamsFwCsv {
      edges {
        node {
          Team
          WL
          HPWL
          SnDWL
          ControlWL
          HPKD
          HPSPM
          HPDPM
          KillEff
          HPMaps
          CTLRoundWin
          CTLKD
          CTLSPM
          CTLDPM
          CTLRounds
          SNDRoundWin
          SNDKD
          SNDSPR
          SNDDPR
          SNDFBRate
          SNDRounds
          SNDHeal
        }
      }
    }
  }
`
